export function truncate(str, len) {
 return str.length <= len ? str : str.substring(0, len) + "...";
}

export function numberWithCommas(x) {
  if (typeof(x) === 'undefined') {
    x = 0;
  }

  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function animateValue(id, start, end, duration) {
  // assumes integer values for start and end
  var obj = document.getElementById(id);

  if(obj === null) { return; }

  var range = end - start;
  // no timer shorter than 50ms (not really visible any way)
  var minTimer = 50;
  // calc step time to show all interediate values
  var stepTime = Math.abs(Math.floor(duration / range));

  // never go below minTimer
  stepTime = Math.max(stepTime, minTimer);

  // get current time and calculate desired end time
  var startTime = new Date().getTime();
  var endTime = startTime + duration;
  var timer;

  function run() {
    var now = new Date().getTime();
    var remaining = Math.max((endTime - now) / duration, 0);
    var value = Math.round(end - (remaining * range));
    obj.innerHTML = numberWithCommas(value);
    if (value == end) {
      clearInterval(timer);
    }
  }

  timer = setInterval(run, stepTime);
  run();
}
