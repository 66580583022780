'use strict';

import $ from "jquery"
require("bootstrap")

$(document).on('turbolinks:load', function() {
  // THEME COLORS
  var style = getComputedStyle(document.body);
  window.chartColors = ["#696ffb", "#7db8f9", "#05478f", "#00cccc", "#6CA5E0", "#1A76CA"];
  var primaryColor = style.getPropertyValue('--primary');
  var secondaryColor = style.getPropertyValue('--secondary');
  var successColor = style.getPropertyValue('--success');
  var warningColor = style.getPropertyValue('--warning');
  var dangerColor = style.getPropertyValue('--danger');
  var infoColor = style.getPropertyValue('--info');
  var darkColor = style.getPropertyValue('--dark');

  // BODY ELEMENTS
  var Body = $("body");
  var TemplateSidebar = $('.sidebar');
  var TemplateHeader = $('.t-header');
  var PageContentWrapper = $(".page-content-wrapper");
  var DesktopToggler = $(".t-header-desk-toggler");
  var MobileToggler = $(".t-header-mobile-toggler");

  if ($('body').hasClass("dark-theme")) {
    window.chartFontcolor = '#b9c0d3';
    window.chartGridLineColor = '#383e5d';

  } else {
    window.chartFontcolor = '#6c757d';
    window.chartGridLineColor = 'rgba(0,0,0,0.08)';
  }

  // SIDEBAR TOGGLE FUNCTION FOR LARGE SCREENS (SCREEN "LG" AND UP)
  DesktopToggler.on("click", function () {
    $(Body).toggleClass("sidebar-minimized");
  });

  // SIDEBAR TOGGLE FUNCTION FOR MOBILE (SCREEN "MD" AND DOWN)
  MobileToggler.on("click", function () {
    $(".page-body").toggleClass("sidebar-collpased");
  });


  // CHECK FOR CURRENT PAGE AND ADDS AN ACTIVE CLASS FOR TO THE ACTIVE LINK
  var current = location.pathname.split("/").slice(-1)[0].replace(/^\/|\/$/g, '');

  $('.navigation-menu li a', TemplateSidebar).each(function () {
    var $this = $(this);
    if (current === "") {
      //FOR ROOT URL
      if ($this.attr('href') === "/") {
        $(this).parents('li').last().addClass('active');
        if ($(this).parents('.navigation-submenu').length) {
          $(this).addClass('active');
        }
      }
    } else {
      //FOR OTHER URL
      if ($this.attr('href') === location.pathname) {
        $(this).parents('li').last().addClass('active');
        if ($(this).parents('.navigation-submenu').length) {
          $(this).addClass('active');
        }
        if (current !== "/") {
          $(this).parents('li').last().find("a").attr("aria-expanded", "true");
          if ($(this).parents('.navigation-submenu').length) {
            $(this).closest('.collapse').addClass('show');
          }
        }
      }
    }
  });

  $(".t-header-toggler").click(function () {
    $(".t-header-toggler").toggleClass("arrow");
  });

  // SIDEBAR COLLAPSE FUNCTION
  $(".sidebar .navigation-menu > li > a[data-toggle='collapse']").on("click", function () {
    $(".sidebar .navigation-menu > li").find('.collapse.show').collapse('hide');
    $(".sidebar .sidebar_footer").removeClass("opened");
  });

  $(".btn.btn-like").on("click", function () {
    $(this).toggleClass("clicked");
    $(this).find("i").toggleClass("mdi-heart-outline clicked").toggleClass("mdi-heart");
  });

  if ($('.animated-count').length) {
    $('.animated-count').counterUp({
      delay: 50,
      time: 800
    });
  }

  $('[data-toggle="tooltip"]').tooltip({
    animation: true,
    delay: 100,
  });
});