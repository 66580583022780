import $ from "jquery"
import "chart.js"
import ApexCharts from "apexcharts"
import { numberWithCommas, animateValue } from "../utils/utils.js";
import { disableSubmit, restoreSubmit, displayError } from "../utils/ui_helpers.js";
import "jquery-toast-plugin";

function fillEngagements(engagementData) {
  let engagements = ['shares', 'comments', 'reactions'],
      hasError = engagementData && engagementData.error;

  for (let engagement of engagements) {
    let val = 0,
        percVal = 0;

    if(hasError) {
      val = 0;
    } else if(engagementData) {
      val = engagementData['shares']['facebook:engagement'][engagement];

      try {
        percVal = engagementData['changes']['facebook:engagement'][engagement];
      } catch(err) {
        percVal = 0;
      }
    } else {
      val = Math.floor(Math.random() * 3000) + 500;
      percVal = Math.floor(Math.random() * 20) + 40;
    }

    animateValue(`value-${engagement}`, 0, val, 2000);

    if(percVal > 0) {
      $(`#change-${engagement}`).parent().show();
      animateValue(`change-${engagement}`, 0, percVal, 1000);
    } else {
      $(`#change-${engagement}`).parent().hide();
    }
  }
}

function fillCounters(counterData) {
  let networks = ['facebook', 'pinterest', 'buffer', 'vkontakte', 'total'],
      hasError = counterData && counterData.error,
      total = 0;

  for(let network of networks) {
    let val = 0,
        percVal = 0;

    if(network === "total" && !counterData) {
      val = total;
    } else if(hasError) {
      val = 0;
    } else if(counterData) {
      val = counterData['shares'][`${network}:share`];

      try {
        percVal = counterData['changes'][`${network}:share`];
      } catch(err) { percVal = 0; }
    } else {
      val = Math.floor(Math.random() * 5000) + 500;
    }

    if(!counterData) {
      percVal = Math.floor(Math.random() * 20) + 40;
    }

    if (typeof(val) === 'undefined') {
      val = 0;
    }

    total += val;

    animateValue(`value-${network}`, 0, val, 1000);

    if(percVal > 0) {
      $(`#change-${network}`).parent().show();
      animateValue(`change-${network}`, 0, percVal, 1000);
    } else {
      $(`#change-${network}`).parent().hide();
    }
  }
}

$(document).on("turbolinks:load", function() {
  "use strict";

  if($("div.home").length === 0) { return; }

  $("#notification-dropdown").on("click", function(e) {
    if(e.originalEvent !== undefined) {
      e.stopPropagation();
      window.location = $(this).attr("href");
    }
  });

  $("form").on("submit", function() {
    if($("#url").val().length === 0) { return false; }
  });

  $("form").on("ajax:beforeSend", function(e) {
    // force counters to 0
    fillEngagements({ error: true });
    fillCounters({ error: true });

    $("#value-url").text($("#url").val());
    $("#url").val("");
    disableSubmit();
  });

  $("form").on("ajax:error", function(e) {
    displayError(e);

    fillEngagements({ error: true });
    fillCounters({ error: true });
  });

  $("form").on("ajax:success", function(e) {
    setTimeout(function(){
      restoreSubmit();

      $("#csv-export").removeClass("disabled");
    }, 2000);

    let data = e.detail[0];

    fillEngagements(data);

    fillCounters(data);

    if($("#notification-dropdown").length > 0 && data['session_calls'] === 1) {
      setTimeout(function(){
        $("#notification-dropdown").trigger("click");
      }, 2500);
    }
  });

  var StatsLineOptions = {
    scales: {
      responsive: false,
      maintainAspectRatio: true,
      yAxes: [{
        display: false
      }],
      xAxes: [{
        display: false
      }]
    },
    legend: {
      display: false
    },
    elements: {
      point: {
        radius: 0
      }
    },
    stepsize: 100
  }

  let colorGradient = {};

  colorGradient.facebook = ['#B721FF', '#21D4FD'];
  colorGradient.pinterest = ['#FEE140', '#FA709A'];
  colorGradient.buffer = ['#ced7df', '#323b43'];
  colorGradient.vkontakte = ['#4376A6', '#21D4FD'];
  colorGradient.total = ['#08AEEA', '#2AF598'];

  fillEngagements();

  fillCounters();

  let networks = ['facebook', 'pinterest', 'buffer', 'vkontakte', 'total'];

  for(let network of networks) {
    if ($(`#stat-line_${network}`).length) {
      var lineChartCanvas = $(`#stat-line_${network}`).get(0).getContext("2d");
      var gradientStroke = lineChartCanvas.createLinearGradient(100, 60, 30, 0);
      gradientStroke.addColorStop(0, colorGradient[network][0]);
      gradientStroke.addColorStop(1, colorGradient[network][1]);
      var lineChart = new Chart(lineChartCanvas, {
        type: 'line',
        data: {
          labels: ["Day 1", "Day 2", "Day 3", "Day 4", "Day 5", "Day 6", "Day 7", "Day 8", "Day 9", "Day 10", "Day 11", "Day 12", "Day 13"],
          datasets: [{
            label: 'Profit',
            data: [7, 6, 9, 7, 8, 6, 8, 5, 7, 8, 6, 7, 7],
            borderColor: gradientStroke,
            borderWidth: 3,
            fill: false
          }]
        },
        options: StatsLineOptions
      });
    }
  }
});