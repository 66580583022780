import $ from "jquery"

export function disableSubmit() {
  $("button.btn-success").addClass("btn-loading").attr("disabled", "disabled");
  $("button.btn-success").html('<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> <span class="sr-only">Loading...</span>');
  $("div.alert").hide();
}

export function restoreSubmit() {
  $("button.btn-success").removeClass("btn-loading").attr("disabled", null);
  $("button.btn-success").html("Count Shares");
}

export function displayError(event) {
  let error = event.detail[0];

  $("button.btn-success").removeClass("btn-loading").attr("disabled", null);
  $("button.btn-success").html("Count Shares");
  $("div.alert").html(error).show();
}